<template>
  <div class="header">
    {{ $t('ProductDiagnosis.productDiagnosis') }}
  </div>
  <hr class="line"/>
  <div class="line-text">
    <span>{{ $t('ProductDiagnosis.systemInformation') }}</span>
    <span @click="reconnect" class="double-dash" :class="{ 'disabled': !canReconnect || isTesting }"><el-icon>
        <Switch/>
      </el-icon>{{ $t('ProductDiagnosis.reconnect') }}</span>
  </div>

  <el-dialog
      :title="$t('ProductDiagnosis.hint')"
      style="text-align: left;z-index: 201"
      v-model="showDialog"
      width="400"
      :show-close="false"
      :close-on-click-modal="false"
  >
    <p>{{ $t('ProductDiagnosis.isThereErrorCode') }}</p>
    <span class="dialog-footer">
      <el-button class="custom-reset-button" @click="handleDialogClose">{{ $t('ProductDiagnosis.no') }}</el-button>
      <el-button class="button-background-color" @click="handleConfirm">{{ $t('ProductDiagnosis.yes') }}</el-button>
    </span>
  </el-dialog>

  <!-- 公共弹框组件 -->
  <CommonDialog
      :visible="showSecondDialog"
      @update:visible="showSecondDialog = $event"
  />

  <!-- 第三个弹窗 -->
  <el-dialog
      :title="$t('ProductDiagnosis.quickDiagnosis')"
      style="text-align: left;"
      v-model="showThirdDialog"
      width="500">
    <!-- 弹窗内容，根据需要进行设计 -->
    <el-form ref="form" model="form">
      <el-form-item>
        <label>{{ $t('ProductDiagnosis.errorCode') }}:</label>
        <el-select v-model="errorCode" :placeholder="$t('ProductDiagnosis.pleaseSelect')"
                   style="width: 100%"
                   @change="getSolutionLinkForErrorCode">
          <el-option
              v-for="option in errorCodeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    {{ descInfo }}
    <div v-if="solutionLinkForCode" style="padding-top: 16px;">
      {{ $t('ProductDiagnosis.clickToViewInfo') }} <a :href="solutionLinkForCode" target="_blank"
         style="color: rgb(0,154,68); text-decoration: none;">Click here</a>
    </div>

  </el-dialog>

</template>

<script>

import {Switch} from "@element-plus/icons-vue";
import EventBus from "@/eventBus";
import MyMixin from "@/mixins/Mixin";
import {pluginStatusMixin} from "@/mixins/Mixin";
import {
  getFaultCodeSimple
} from "@/api/api";
import CommonDialog from "@/views/ProductDiagnosis/components/CommonDialog.vue";

export default {
  components: {CommonDialog, Switch},
  mixins: [MyMixin, pluginStatusMixin],
  data() {
    return {
      canReconnect: true, // 控制重新连接按钮是否可用
      isTesting: false,  // 添加新状态跟踪测试是否进行中

      showDialog: false, // 控制是否显示弹框的状态
      showSecondDialog: false, // 新增的控制第二个弹窗显示的状态
      showThirdDialog: false, // 控制第三个弹窗显示的状态

      errorCode: '',

      errorCodeOptions: [],  // 存储故障代码的选项

      previousAllDevicesConnecting: false,

      solutionLinkForCode: '',
      descInfo: '',
    };
  },
  watch: {
    isDisplayConnecting() {
      this.checkDisplayConnectionStatus();
    },
  },
  mounted() {
    EventBus.on('analysis-status-change', this.handleAnalysisStatusChange);
    EventBus.on('test-status-change', this.handleTestStatusChange);  // 监听测试状态变化
  },
  beforeUnmount() {
    EventBus.off('analysis-status-change', this.handleAnalysisStatusChange);
    EventBus.off('test-status-change', this.handleTestStatusChange);  // 移除监听器
  },
  methods: {

    getSolutionLinkForErrorCode() {
      const selectedCode = this.errorCodeOptions.find(option => option.value === this.errorCode);
      if (selectedCode) {
        this.descInfo = selectedCode.label_desc;
        this.solutionLinkForCode = selectedCode.faq_url; // 使用 label 作为 URL
      } else {
        this.descInfo = '';
        this.solutionLinkForCode = '';
      }
    },
    handleDialogClose() {
      this.showDialog = false;
      this.$nextTick(() => {
        // 等待 showDialog 变更被处理后执行
        this.showSecondDialog = true;  // 显示第二个弹窗
        this.resetDropdowns();
      });
    },
    handleConfirm() {
      // 确认按钮的逻辑
      this.showDialog = false;
      this.$nextTick(() => {
        // 等待 showDialog 变更被处理后执行
        this.showThirdDialog = true;  // 显示第三个弹窗
        this.resetDropdowns();
        this.fetchErrorCodes();
      });
    },
    resetDropdowns() {
      // 重置所有相关的下拉选项和选中的值
      this.errorCode = '';
      this.errorCodeOptions = [];
    },
    async reconnect() {
      // 在重新连接的逻辑后面添加状态检查
      if (this.canReconnect && !this.isTesting) {

        EventBus.emit('reset-all-data');
        await this.checkPluginStatus();
        // 确保 DOM 更新已完成
        await this.$nextTick();
        this.checkDeviceStatusAndShowDialog();

        EventBus.emit('reconnect'); // 触发重新连接事件
      } else if (this.isAnalyzing) {
        this.$message({message: this.$t('ProductDiagnosis.analysisInProgress'), type: 'warning'});
      } else if (this.isTesting) {
        this.$message({message: this.$t('ProductDiagnosis.testingInProgress'), type: 'warning'});
      }
    },

    checkDeviceStatusAndShowDialog() {
        if (this.isMotorConnecting) {
          this.showDialog = true;
        }
    },

    checkDisplayConnectionStatus() {
      if (!this.isDisplayConnecting && (!this.isDisplayConnected && !this.isMotorConnected)) {
        if (!this.showSecondDialog && !this.showThirdDialog) {
          this.showDialog = true;
        }
      }
    },

    handleAnalysisStatusChange(isAnalyzing) {
      this.canReconnect = !isAnalyzing; // 如果正在分析，禁用重新连接
    },
    handleTestStatusChange(isTesting) {
      this.isTesting = isTesting;  // 更新测试状态
    },

    async fetchErrorCodes() {
      try {
        const response = await getFaultCodeSimple();
        if (response && response.code === 0 && response.data) {
          const lang = this.$store.getters.currentLanguage;  // 获取当前语言环境
          this.errorCodeOptions = response.data.map((item) => {
            let label = item[lang];
            if (!label) {
              // 如果当前语言没有匹配的值，使用 `zh` 或者其他默认语言
              label = item.en || item.zh; // 这里假设 `zh` 和 `en` 是可用的默认语言
            }
            let lang_desc = `${lang}_desc`;  // 动态构建描述字段
            let label_desc = item[lang_desc];
            return {
              label_desc: label_desc,
              label: label,
              faq_url: item.faq_url,
              value: item.id
            };
          });
        }
      } catch (error) {
        console.error('Failed to fetch error codes:', error);
        this.$message.error(this.$t('ProductDiagnosis.failedToFetchErrorCodes'));
      }
    },

  },
  computed: {
    isMotorConnecting() {
      return this.$store.state.isMotorConnecting;
    },
    isBatteryConnecting() {
      return this.$store.state.isBatteryConnecting;
    },
    isDisplayConnecting() {
      return this.$store.state.isDisplayConnecting;
    },
    isMotorConnected() {
      return this.$store.state.isMotorConnected;
    },
    isDisplayConnected() {
      return this.$store.state.isDisplayConnected;
    },
    isBatteryConnected() {
      return this.batteryInfo && Object.keys(this.batteryInfo).length > 0;
    },
    batteryInfo() {
      return this.$store.state.batteryInfo;
    },
  },
};
</script>

<style scoped>

.disabled {
  cursor: not-allowed;
  color: #ccc;
}

.header {
  text-align: left;
  line-height: 0.5em;
  /* 垂直居中，根据您的需求调整行高 */
  margin-top: 20px;
  margin-left: 20px;
  font-size: 15px;
}

.line {
  margin-top: 20px;
  border: none;
  border-top: 1px solid rgba(221, 221, 221, 0.6);
}

.line-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  color: #555;
  margin-bottom: 20px;
  font-size: 15px;
  margin-left: 8px;
}

.line-text span {
  padding: 0 10px;
  cursor: pointer;
}

.double-dash {
  color: rgba(0, 154, 68, 0.8);
  color: #009A44;
  margin-left: -5px;
}
</style>
  