<template>
  <div>
    <!-- 开始诊断按钮 -->
    <div class="diagnosis-button">

      <el-button type="info" @click="fetchDiagnosisData"
                 :disabled="!isMotorConnected || isMotorConnecting || isBatteryConnecting || isDisplayConnecting"
                 :class="{ 'custom-button': true, 'button-active': isMotorConnected  && !isMotorConnecting && !isBatteryConnecting && !isDisplayConnecting}">
        <el-icon class="custom-icon">
          <CaretRight/>
        </el-icon>
        {{ $t('ProductDiagnosis.startDiagnosis') }}
      </el-button>&nbsp;&nbsp;

      <span>{{ $t('ProductDiagnosis.nakeSureBattery') }}</span>
    </div>

    <!-- 加载动画 -->
    <div class="table-container">

      <!-- 自检表格表头 -->
      <el-table v-loading="isLoading" element-loading-text="Loading..."
                :header-cell-style="{background:'rgb(250, 250, 250)'}" :row-style="{ height: '65px' }"
                :data="diagnosisData" style="width: 100%">
        <el-table-column :label="$t('ProductDiagnosis.sequenceNumber')" type="index" :width="200"></el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.module')" :width="120">
          <template v-slot="scope">
            {{ scope.row.productType === 1 ? $t('ProductDiagnosis.display') : $t('ProductDiagnosis.motor') }}
          </template>
        </el-table-column>
        <el-table-column :width="300" :label="$t('ProductDiagnosis.selfCheckItems') " prop="errName">
          <template v-slot:default="scope">
            <div style="white-space: normal; word-break: normal; overflow-wrap: break-word;">
              {{ scope.row.errName }}
            </div>
          </template>
        </el-table-column>

        <el-table-column :width="300" :label="$t('ProductDiagnosis.selfCheckResults')">

          <template #default="scope">
            <el-tooltip v-if="scope.row.isErrorName === 2" :content="$t('ProductDiagnosis.ensureVoltageNormal')"
                        placement="right">
              <el-tag :type="'warning'">
                {{ $t('ProductDiagnosis.warning') }}
              </el-tag>
            </el-tooltip>
            <el-tag
                v-else
                :type="scope.row.isErrorName === 1 ? 'danger' : 'success'"
            >
              {{
                scope.row.isErrorName === 1 ? $t('ProductDiagnosis.abnormal') : $t('ProductDiagnosis.normal')
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="$t('ProductDiagnosis.helpManual') " prop="manual">
          <template #default="scope">
            <template v-if="scope.row.manual_link !== '-'">
              <a :href="scope.row.manual_link" target="_blank" style="text-decoration: none; color: rgb(0, 154, 68);">
                {{ scope.row.manual }}
              </a>
            </template>
            <template v-else>
              {{ scope.row.manual }}
            </template>
          </template>
        </el-table-column>

        <template v-slot:empty>
          <EmptySlot/>
        </template>
      </el-table>
    </div>

    <!-- 公共弹框组件 -->
    <CommonDialog
        :visible="showDialog"
        @update:visible="showDialog = $event"
    />

  </div>
</template>

<script>
import {CaretRight} from "@element-plus/icons-vue";
import {getErrorName, getSystemCheckData, recordOperation} from "@/api/api";
import EventBus from "@/eventBus";
import CommonDialog from "@/views/ProductDiagnosis/components/CommonDialog.vue";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  name: "SystemSelfCheck",
  components: {EmptySlot, CommonDialog, CaretRight},
  data() {
    return {
      diagnosisData: [], // 自检数据，需要从后端获取或者填充
      isLoading: false, // 添加加载状态
      showDialog: false,
      motorHardwareVersion: null,
    }
  },
  watch: {
    '$i18n.locale'() {
      if (!(!this.isMotorConnected || this.isMotorConnecting || this.isBatteryConnecting || this.isDisplayConnecting)) {
        this.fetchDiagnosisData();
      }
    },
  },
  props: {
    motorTypeAndModelData: Object
  },
  mounted() {
    EventBus.on('SelfErrorResponse', this.handleMotorSelfErrorData);
    EventBus.on('reset-all-data', this.clearData);
    EventBus.on('MotorTypeAndModelDataMessage', this.handleMotorHardwareVersionResponse);
  },
  beforeUnmount() {
    EventBus.off('SelfErrorResponse', this.handleMotorSelfErrorData);
    EventBus.off('reset-all-data', this.clearData);
    EventBus.off('MotorTypeAndModelDataMessage', this.handleMotorHardwareVersionResponse);
  },
  computed: {
    getWs() {
      return this.$store.state.ws;
    },
    isMotorConnected() {
      return this.$store.state.isMotorConnected;
    },
    isMotorConnecting() {
      return this.$store.state.isMotorConnecting;
    },
    isBatteryConnecting() {
      return this.$store.state.isBatteryConnecting;
    },
    isDisplayConnecting() {
      return this.$store.state.isDisplayConnecting;
    },
    isDisplayConnected() {
      return this.$store.state.isDisplayConnected;
    },
  },

  methods: {
    handleMotorHardwareVersionResponse(message) {
      this.motorHardwareVersion = message.hardwareVersion;
    },
    shouldDisplayKeyTest(itemName) {
      const allowedHardwareVersions = ['MC4F10', 'C4B6C0', 'C4B1C1', 'C4M5C0', 'C4F1C1', 'MC4F1C', 'MC4F0C', 'MC4B1C'];
      const keyTestNames = ['按键检测', 'Key failure','Guasto al tasto','Défaillance du bouton','Tastenausfall','Toets falen','キー故障','Tangentfel','Billentyűzet meghibásodása','Awaria klawisza']; // 按键检测的中文和英文名称
      const hardwareVersion = this.motorHardwareVersion;
      // 如果硬件版本号不在允许列表中，并且项目名称包含按键检测的名称，则返回 false
      return !(!allowedHardwareVersions.includes(hardwareVersion) && keyTestNames.some(testName => itemName.includes(testName)));
    },
    clearData() {
      this.diagnosisData = [];
    },
    handleMotorSelfErrorData(data) {
      // 假设data是错误代码的数组，根据你的实际数据结构可能需要调整
      const errorCodes = (data.data || '').split(',');

      // 从这里开始，原本在fetchDiagnosisData中对错误代码处理的逻辑现在移至此处
      let diagnosisDataWithErrorCodes = errorCodes.map(code => ({errCode: code.trim(), isError: true}));

      // 请确保processFaultData和fetchSystemCheckData方法可以处理异步逻辑
      this.processFaultData(diagnosisDataWithErrorCodes).then(processedData => {
        this.fetchSystemCheckData().then(() => {
          // 一旦系统自检数据和错误代码处理完毕，进行数据合并
          this.diagnosisData = [...processedData, ...this.diagnosisData.filter(item =>
              !processedData.find(errorItem => item.code.includes(errorItem.errCode))
          )];

          // 去重逻辑，根据name字段去除重复项
          const uniqueDiagnosisData = [];
          const nameSet = new Set();
          const errnameSet = new Set();

          for (const item of this.diagnosisData) {
            if (item.name) {
              // 如果存在 name 字段，根据 name 字段去重
              if (!nameSet.has(item.name)) {
                nameSet.add(item.name);
                uniqueDiagnosisData.push(item);
              }
            } else if (item.errName) {
              // 如果不存在 name 字段，但存在 errname 字段，根据 errname 字段去重
              if (!errnameSet.has(item.errName)) {
                errnameSet.add(item.errName);
                uniqueDiagnosisData.push(item);
              }
            } else {
              // 如果既没有 name 也没有 errname 字段，直接保留
              uniqueDiagnosisData.push(item);
            }
          }

          this.diagnosisData = uniqueDiagnosisData;

          // 判断是否无异常数据
          const noErrorData = this.diagnosisData.every(item => item.isErrorName !== 1 && item.isErrorName !== 2);
          if (noErrorData) {
            this.showDialog = true; // 显示弹框
          }
          // 在这里触发自定义事件，传递最终的diagnosisData给父组件
          this.$emit('update-self-check-data', this.diagnosisData);
          // 数据处理完毕，隐藏加载动画
          this.isLoading = false;
        }).catch(error => {
          console.error("Failed to fetch system check data:", error);
          this.isLoading = false; // 确保即使出错也会停止加载动画
        });
      }).catch(error => {
        console.error("Error processing fault data:", error);
        this.isLoading = false; // 确保即使出错也会停止加载动画
      });
    },

    sendCommand(command) {
      if (this.getWs.readyState === WebSocket.OPEN) {
        this.getWs.send(JSON.stringify(command));
      } else {
        console.error(this.$t('ProductDiagnosis.websocketNotConnected'));
      }
    },

    async fetchDiagnosisData() {
      this.isLoading = true; // 开始加载时，设置 isLoading 为 true
      const command = {
        "cmd": "getMotorInfo_SelfError",
        "param": "",
      };
      this.sendCommand(command);

      try {
        // 记录操作，传递一个包含 action 和 result 的 data 对象
        await recordOperation({ action: 'system-self-check' });
      } catch(error) {
        console.error("Failed to record operation:", error);
      }
    },

    async fetchSystemCheckData() {
      try {
        // 确定需要查询的产品类型
        let productTypes = [];
        if (this.isMotorConnected) {
          productTypes.push(0); // 如果电机已连接，添加电机对应的类型
        }

        let allCheckData = []; // 用于收集所有类型的自检数据
        const currentLanguage = this.$store.getters.currentLanguage;
        // 遍历所有需要查询的产品类型
        for (let type of productTypes) {
          const systemCheckData = await getSystemCheckData(type);
          allCheckData = allCheckData.concat(systemCheckData.data.map(item => {
            let motor_type = 0;
            if (this.motorTypeAndModelData?.motorType === 'hub') {
              motor_type = 1;
            }

            const matchedDocument = item.documents.find(doc => doc.lang === currentLanguage && doc.type === motor_type)  || item.documents.find(doc => doc.lang === 'en' && doc.type === motor_type)  || item.documents[0];

            if (!this.shouldDisplayKeyTest(matchedDocument.name)) {
              return null; // 不显示“按键检测”项
            }

            let manual = '-';
            let manual_link = '-';

            return {
              ...item,
              errName: matchedDocument.name,
              isErrorName: 0,
              productType: type, // 给每项数据添加productType字段
              measures: this.$t('ProductDiagnosis.referenceHelpManual'),
              manual: manual,
              manual_link: manual_link,
            };
          }).filter(item => item !== null));
        }
        // 这里确保 allCheckData 不包含 null
        allCheckData = allCheckData.filter(item => item !== null && typeof item.isErrorName !== 'undefined');


        this.diagnosisData = allCheckData.sort((a, b) => {
          return b.isErrorName - a.isErrorName || a.productType - b.productType;
        });
      } catch (error) {
        console.error(this.$t('ProductDiagnosis.fetchSystemCheckFailed'), error);
      }
    },

    //异常为1，正常为0
    async processFaultData(validFaults) {
      const currentLanguage = this.$store.getters.currentLanguage; // 获取当前语言
      const successfulFaults = []; // 创建一个临时数组存储成功处理的数据
      const specialErrorCodes = ['09', '36', '06', '39', '46', '05', '20', '04', '50', '44', '38']; // 特定错误代码列表

      for (let i = 0; i < validFaults.length; i++) {
        if (!validFaults[i].errCode) {
          continue; // 如果errCode为空或未定义，跳过当前循环
        }
        try {
          // 如果getErrorName方法需要字符串类型的参数，再将其转换回字符串
          const nameData = await getErrorName(validFaults[i].errCode);
          if (!(!nameData || !nameData.data || nameData.data.length === 0)) {
              let motor_type = 0;
            if (this.motorTypeAndModelData?.motorType === 'hub') {
              motor_type = 1;
            }
            // 找到匹配当前语言的文档，如果没有则取第一个文档
            const document = nameData.data[0].documents.find(doc => doc.lang === currentLanguage && doc.type === motor_type) || nameData.data[0].documents.find(doc => doc.lang === 'en' && doc.type === motor_type)  || nameData.data[0].documents[0];

            if (!this.shouldDisplayKeyTest(document.name)) {
              continue; // 不显示“按键检测”项
            }
            let isErrorName = 1;
            if (specialErrorCodes.includes(validFaults[i].errCode)) {
              isErrorName = 2; // 如果错误代码在特定列表中，设置为2
            }

            let manual = '-';
            let manual_link = '-';

            if (nameData.data[0].document_type === 1) {
              // 如果 document_type 为 1，使用 content 作为手册内容
              manual = document.content || '-'; // 如果 content 也不存在，使用 '-'
              manual_link = '-'; // 文本类型不提供链接
            } else if (nameData.data[0].document_type === 0) {
              // 如果 document_type 为 0，使用文件名和链接
              manual = document.file_name || '-';
              manual_link = document.fileUrl || '-'; // 如果 fileUrl 也不存在，使用 '-'
            }

            // 添加成功获取到的数据到临时数组
            successfulFaults.push({
              ...validFaults[i], // 保留原始数据
              errName: document.name,
              productType: nameData.data[0].product_type, // 给每项数据添加productType字段
              measures: this.$t('ProductDiagnosis.referenceHelpManual'),
              manual: manual, // 如果 document.file 为空，使用 '-'
              manual_link: manual_link, // 如果 document.fileUrl 为空，使用 '-'
              isErrorName: isErrorName
            });
          }
        } catch (error) {
          console.error(this.$t('ProductDiagnosis.processFaultDataFailed'), error);
        }
      }
      return successfulFaults; // 返回临时数组，其中只包含成功处理的数据
    },
  }
}
</script>

<style scoped>

.table-container {
  position: relative;
  height: 600px; /* 根据实际高度调整 */
  overflow-y: auto;
}

.word-family {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 设置字体系列 */
}

.custom-icon {
  margin-right: 10px; /* 设置右侧边距 */
}

.diagnosis-button {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.diagnosis-button span {
  margin-left: 10px;
}

.custom-button {
  background-color: rgb(245, 245, 245); /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217); /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184); /* 设置边框颜色为红色，如果需要 */
}

.custom-button:hover {
  background-color: rgb(245, 245, 245); /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217); /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184); /* 鼠标悬停时保持相同的文字颜色 */
}

/* 当按钮可点击时应用的样式 */
.button-active {
  background-color: rgb(0, 154, 68); /* 设置背景为白色 */
  color: white; /* 可以调整文字颜色以保持可读性 */
  border-color: #dcdfe6; /* 可选：设置边框颜色 */
}

/* 悬停在 .button-active 上时的样式 */
.button-active:hover {
  color: white; /* 悬停时文字颜色为绿色 */
  border-color: rgb(0, 154, 68); /* 悬停时边框颜色为绿色 */
  background-color: rgb(0, 154, 68); /* 悬停时背景色为白色 */
}

</style>
