<template>
  <div>
    <div class="analysis-button">
      <el-button type="info" @click="isAnalyzing ? stopAnalysis() : startAnalysis()"
                 :disabled="!isMotorConnected || isMotorConnecting || isBatteryConnecting || isDisplayConnecting"
                 :class="{ 'custom-button': true, 'button-active': isMotorConnected  && !isMotorConnecting && !isBatteryConnecting && !isDisplayConnecting}">
        <el-icon v-if="!isAnalyzing">
          <CaretRight/>
        </el-icon>

        <el-icon v-if="isAnalyzing">
          <PauseIcon/>
        </el-icon>

        {{ isAnalyzing ? $t('ProductDiagnosis.stopAnalysis') : $t('ProductDiagnosis.startAnalysis') }}
      </el-button>&nbsp;&nbsp;
      <span>{{ $t('ProductDiagnosis.ensureBatteryIsOn') }}</span>
    </div>

    <el-dialog :title="$t('ProductDiagnosis.selectAnalysisData')" width="520" v-model="analysisDialogVisible"
               style="text-align: left;" @open="disableScroll" @close="enableScroll">
      <el-form label-position="top">
        <el-form-item :label="$t('ProductDiagnosis.motor')">
          <el-checkbox-group v-model="selectedMotorOptions">
            <el-checkbox v-for="option in motorOptions" :label="option.label" :key="option.value">
              {{ option.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item :label="$t('ProductDiagnosis.battery')">
          <el-checkbox-group v-model="selectedBatteryOptions">
            <el-checkbox v-for="option in batteryOptions" :label="option.value" :key="option.value"
                         :disabled="!isBatteryConnected">
              {{ option.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

      </el-form>
      <hr class="dialog-separator"/>
      <div class="dialog-footer">
        <el-button class="custom-reset-button" @click="analysisDialogVisible = false">{{
            $t('ProductDiagnosis.cancel')
          }}
        </el-button>
        <el-button class="button-background-color" @click="confirmAnalysis">{{
            $t('ProductDiagnosis.confirm')
          }}
        </el-button>
      </div>
    </el-dialog>

    <div class="device-info">
      <hr class="separator">
      <div class="device-item">
        <img class="before-three-field" :src="motorImgUrl" width="40" height="40" alt="motor"/>
        &nbsp;&nbsp;<p class="before-three-field"><span
          class="motor-font">
        {{
          motorTypeAndModelData?.motorType === 'mid' ? $t('ProductDiagnosis.centerMotor') :
              motorTypeAndModelData?.motorType === 'hub' ? $t('ProductDiagnosis.wheelMotor') :
                  $t('ProductDiagnosis.motor')
        }}
      </span></p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="status " :class="{ online: isMotorConnected }" :style="motorStatusStyle">{{
            isMotorConnected ?
                $t('ProductDiagnosis.online') : $t('ProductDiagnosis.offline')
          }}</span>

        <div class="field">
          <p class="field-label ">{{ $t('ProductDiagnosis.deviceModel') }}</p>
          <p>{{ motorTypeAndModelData?.model || '-' }}</p>
        </div>

        <div class="field">
          <p class="field-label">{{ $t('ProductDiagnosis.serialNumber') }}</p>
          <p>{{ motorInfo?.sncode || '-' }}</p>
        </div>

        <div class="field">
          <p class="field-label">{{ $t('ProductDiagnosis.firmwareVersion') }}</p>
          <p>{{ motorInfo?.softwareversion || '-' }}</p>
        </div>

        <div class="field">
          <p class="field-label">{{ $t('ProductDiagnosis.hardwareVersion') }}</p>
          <p>{{ motorInfo?.hardwareversion || '-' }}</p>
        </div>
      </div>

      <div id="motor-temp-chart"></div>

      <hr class="separator">
      <div class="device-item">

        <img class="before-three-field" :src="batteryImgUrl" width="40" height="40" alt="battery"/>
        &nbsp;&nbsp;<p class="before-three-field"><span class="motor-font">{{ $t('ProductDiagnosis.battery') }}</span>
      </p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="status" :class="{ online: isBatteryConnected }" :style="batteryStatusStyle">
        {{ isBatteryConnected ? $t('ProductUpgrade.online') : $t('ProductUpgrade.offline') }}
      </span>

        <div class="field">
          <p class="field-label">{{ $t('ProductDiagnosis.deviceModel') }}</p>
          <p>{{ batteryInfo?.hardwareversion || '-' }}</p>
        </div>

        <div class="field">
          <p class="field-label">{{ $t('ProductDiagnosis.serialNumber') }}</p>
          <p>{{ batteryInfo?.sncode || '-' }}</p>
        </div>

        <div class="field">
          <p class="field-label">{{ $t('ProductDiagnosis.firmwareVersion') }}</p>
          <p>{{ batteryInfo?.firmwareversion || '-' }}</p>
        </div>

        <div class="field">
          <p class="field-label">{{ $t('ProductDiagnosis.hardwareVersion') }}</p>
          <p>{{ batteryInfo?.hardwareversion || '-' }}</p>
        </div>
      </div>
      <div ref="batteryChartsContainer"></div>

    </div>
    <hr class="separator">
  </div>
</template>

<script>
import {CaretRight} from "@element-plus/icons-vue";
import * as echarts from "echarts";
import EventBus from "@/eventBus";
import dialogMixin from "@/mixins/dialogMixin";
import PauseIcon from '@/components/PauseIcon.vue';
import {recordOperation} from "@/api/api";

export default {
  name: "RealTimeAnalysis",
  components: {CaretRight, PauseIcon},
  mixins: [dialogMixin],
  data() {
    return {
      analysisDialogVisible: false,

      selectedMotorOptions: [],
      selectedBatteryOptions: [],
      batteryImgUrl: require("@/assets/battery.webp"),

      batteryModel: '',
      batterySerialNumber: '',
      batterySoftwareVersion: '',
      batteryHardwareVersion: '',

      motorCharts: [],
      batteryCharts: [],
      motorChart: null, // 用于存储电机图表实例
      batteryChart: null, // 用于存储电机图表实例

      motorData: [],// 清空电机数据数组
      batteryData: [],

      fakeData:
          {
            "Bus_current": "0.0",
            "motor_output_power": "29.7",
            "Motor_rotation_speed": "0.0",
            "MCU_temperature": "30.0",
            "Motor_temperature": "25.0",
          },
      chartInstance: null, // 存储图表实例
      motorDataSeries: [], // 用于存储解析后的数据序列

      isAnalyzing: false,
      dataIntervalId: null,
      timeCounter: 0,
      wsConnected: false, // 新增WebSocket连接状态

      isRealTimeDataEnabled: false, // 新增属性
      batteryDisplays: [],
    };
  },
  props: {
    motorTypeAndModelData: Object
  },
  mounted() {
    EventBus.on('RealTimeWsMessage', this.handleRealTimeWsMessage);
    EventBus.on('BatteryRealTimeWsMessage', this.handleBatteryRealTimeWsMessage);
    //用于点击重新连接清空数据
    EventBus.on('reset-all-data', this.clearData);
    EventBus.on('request-stop-analysis', this.handleStopAnalysisRequest);
  },
  beforeUnmount() {
    EventBus.off('RealTimeWsMessage', this.handleRealTimeWsMessage);
    EventBus.off('BatteryRealTimeWsMessage', this.handleBatteryRealTimeWsMessage);
    //用于点击重新连接清空数据
    EventBus.off('reset-all-data', this.clearData);
    EventBus.off('request-stop-analysis', this.handleStopAnalysisRequest);
  },
  computed: {
    motorImgUrl() {
      if (this.motorTypeAndModelData?.motorType === 'mid') {
        return require("@/assets/image/mid-motor.png");
      } else if (this.motorTypeAndModelData?.motorType === 'hub') {
        return require("@/assets/image/hub-motor.png");
      } else {
        return require("@/assets/image/mid-motor.png");
      }
    },
    motorOptions() {
      return [
        {label: this.$t('ProductDiagnosis.power'), value: 'power'},
        {label: this.$t('ProductDiagnosis.cadence'), value: 'cadence'}, // 新增踏频选项
        {label: this.$t('ProductDiagnosis.speed'), value: 'speed'}, // 新增车速选项
        {label: this.$t('ProductDiagnosis.torqueSensorTorque'), value: 'torque'}, // 新增力矩传感器扭矩选项
        {label: this.$t('ProductDiagnosis.phaseCurrent'), value: 'sensor2'},
      ];
    },
    batteryOptions() {
      return [
        {label: this.$t('ProductDiagnosis.current'), value: 'current'},
        {label: this.$t('ProductDiagnosis.voltage'), value: 'BatVoltage'},
        {label: this.$t('ProductDiagnosis.SOC'), value: 'BatSoc'},
        {label: this.$t('ProductDiagnosis.remainingCapacity'), value: 'BatRemCap'}
      ];
    },
    isMotorConnecting() {
      return this.$store.state.isMotorConnecting;
    },
    isBatteryConnecting() {
      return this.$store.state.isBatteryConnecting;
    },
    isDisplayConnecting() {
      return this.$store.state.isDisplayConnecting;
    },

    CaretRight() {
      return CaretRight;
    },
    isOnline() {
      // 在这里计算属性值并返回
      return this.calculateIsOnline(); // 示例计算方式
    },
    isMotorConnected() {
      return this.$store.state.isMotorConnected;
    },
    motorInfo() {
      return this.$store.state.motorInfo;
    },
    getWs() {
      return this.$store.state.ws;
    },
    isBatteryConnected() {
      if (!this.batteryInfo) {
        return false; // 如果是，认为“电池信息为空”
      }
      return Object.keys(this.batteryInfo).length !== 0;
    },
    batteryInfo() {
      return this.$store.state.batteryInfo;
    },
    motorStatusStyle() {
      return {
        backgroundColor: this.isMotorConnected ? "rgb(246,255,237)" : "rgb(245,245,245)", // 设置背景颜色
        color: this.isMotorConnected ? "rgb(82,186,26)" : "black", // 设置文字颜色
        fontSize: "12px", // 设置字体大小
        padding: "5px 10px", // 设置内边距
        border: this.isMotorConnected ? "1px solid rgb(183,235,143)" : "1px solid #DDD", // 设置圆角
        marginRight: "10px", // 设置右侧间距
      };
    },
    batteryStatusStyle() {
      return {
        backgroundColor: this.isBatteryConnected ? "rgb(246,255,237)" : "rgb(245,245,245)", // 设置背景颜色
        color: this.isBatteryConnected ? "rgb(82,186,26)" : "black", // 设置文字颜色
        fontSize: "12px", // 设置字体大小
        padding: "5px 10px", // 设置内边距
        border: this.isBatteryConnected ? "1px solid rgb(183,235,143)" : "1px solid #DDD", // 设置圆角
        marginRight: "10px", // 设置右侧间距
      };
    },

  },
  methods: {
    handleStopAnalysisRequest(shouldStop) {
      if (shouldStop) {
        this.stopAnalysis();
      }
    },
    clearData() {
      if (!this.isAnalyzing) {
        this.selectedMotorOptions = [];
        this.selectedBatteryOptions = [];
        // 直接清除图表数组，确保没有遗留的引用
        this.motorCharts.forEach(chart => chart.dispose());
        this.batteryCharts.forEach(chart => chart.dispose());
        // 清空数组，确保没有残留的引用
        this.motorCharts = [];
        this.batteryCharts = [];

        // 清空 DOM 容器
        const motorChartsContainer = document.getElementById('motor-temp-chart');
        if (motorChartsContainer) {
          motorChartsContainer.innerHTML = '';
        }

        const batteryChartsContainer = this.$refs.batteryChartsContainer;
        if (batteryChartsContainer) {
          batteryChartsContainer.innerHTML = '';
        }

        this.clearChartData(); // 如果有需要，清除图表数据
        // 发送图表清空完成的事件
        EventBus.emit('analysis-started', false);
      }
    },
    // 根据选项值返回对应的单位
    getUnit(optionValue) {
      switch (optionValue) {
        case this.$t('ProductDiagnosis.power'):
          return '(W)';
        case this.$t('ProductDiagnosis.cadence'):
          return '(rpm)';
        case this.$t('ProductDiagnosis.speed'):
          return '(km/h)';
        case this.$t('ProductDiagnosis.torque'):
          return '(N.m)';
        case this.$t('ProductDiagnosis.current'):
        case this.$t('ProductDiagnosis.phaseCurrent'):
          return '(A)';
        default:
          return ''; // 如果没有匹配的选项，返回空字符串
      }
    },
    calculateIsOnline() {
      // 根据逻辑计算 isOnline 的值
      return false;
    },

    async startAnalysis() {
      this.analysisDialogVisible = true;

      this.timeCounter = 0; // 重置时间计数器
      this.clearChartData(); // 清除图表数据

      try {
        // 记录操作，传递一个包含 action 和 result 的 data 对象
        await recordOperation({ action: 'realtime-analysis' });
      } catch(error) {
        console.error("Failed to record operation:", error);
      }
    },

    clearChartData() {
      // 清空图表数据的方法
      this.motorCharts.forEach(chart => {
        const option = chart.getOption();
        option.series[0].data = []; // 清空数据
        option.xAxis[0].data = []; // 清空x轴标签
        chart.setOption(option);
      });
      this.batteryCharts.forEach(chart => {
        const option = chart.getOption();
        option.series[0].data = []; // 清空数据
        option.xAxis[0].data = []; // 清空x轴标签
        chart.setOption(option);
      });
    },

    stopAnalysis() {
      this.isAnalyzing = false;
      this.$emit('analysis-status', false);  // 通知父组件分析已停止
      EventBus.emit('analysis-status-change', false);
      this.isRealTimeDataEnabled = false; // 禁用实时数据获取
      // 发送停止测试命令
      this.sendCommand('stopMotorTest').then(() => {
        console.log("Analysis stopped successfully.");
      }).catch(error => {
        console.error("Failed to stop analysis:", error);
      });
      if (this.dataIntervalId) {
        clearInterval(this.dataIntervalId);
        this.dataIntervalId = null;
      }
      // 显示消息提示框
      this.$message({
        message: this.$t('ProductDiagnosis.stoppedAnalysis'),
        type: 'success',
        duration: 3000 // 消息显示时间（毫秒）
      });
    },

    confirmAnalysis() {
      if (this.selectedMotorOptions.length === 0 && this.selectedBatteryOptions.length === 0) {
        this.$message.warning(this.$t('ProductDiagnosis.selectOptionWarning'));
        return;
      }

      this.isAnalyzing = true;
      this.$emit('analysis-status', true);  // 通知父组件分析开始
      EventBus.emit('analysis-status-change', true);
      this.analysisDialogVisible = false;
      this.isRealTimeDataEnabled = true; // 启用实时数据获取

      // 根据选择的选项初始化图表
      if (this.selectedMotorOptions.length > 0 || this.selectedBatteryOptions.length > 0) {
        this.$nextTick(() => {
          this.initializeCharts();
          this.initializeBatteryCharts();
          EventBus.emit('analysis-started', true);
        });
      }

      this.sendCommand('startMotorTest')
          .then((response) => {
            if (response.code === 200) {
              // 命令成功，初始化数据接收
              this.simulateDataReceiving();
            } else {
              console.error('Start motor test command failed:', response);
            }
          })
          .catch((error) => {
            console.error('Error sending startMotorTest command:', error);
          });

// 清除现有的图表实例
      this.clearExistingCharts();
      if (this.selectedMotorOptions.length > 0) {
        this.initializeCharts(); // 动态创建图表
      }

      if (this.selectedBatteryOptions.length > 0) {
        this.initializeBatteryCharts();
      }
    },

    sendCommand(cmd) {

      return new Promise((resolve, reject) => {
        // 检查WebSocket连接状态
        if (this.getWs.readyState !== WebSocket.OPEN) {
          // 尝试重新连接WebSocket
          // 等待一段时间后重新检查WebSocket状态
          setTimeout(() => {
            if (this.getWs.readyState !== WebSocket.OPEN) {
              reject('Failed to open WebSocket after reconnection attempt.');
              return;
            }
            this._sendCommandHelper(cmd, resolve, reject);
          }, 1000); // 等待1秒后尝试重新发送命令
        } else {
          this._sendCommandHelper(cmd, resolve, reject);
        }
      });
    },

    _sendCommandHelper(cmd, resolve) {
      const messageHandler = event => {
        const response = JSON.parse(event.data);
        if (response.cmd === cmd) {
          this.getWs.removeEventListener('message', messageHandler);
          resolve(response);
        }
      };

      this.getWs.addEventListener('message', messageHandler);
      this.getWs.send(JSON.stringify({cmd, param: ""}));
    },

    initializeBatteryCharts() {
      // 定义电池数据类型的期望顺序
      const batteryOptionsOrder = ['current', 'BatVoltage', 'BatSoc', 'BatRemCap'];

      // 根据期望顺序对 selectedBatteryOptions 进行排序
      this.selectedBatteryOptions.sort((a, b) => {
        return batteryOptionsOrder.indexOf(a) - batteryOptionsOrder.indexOf(b);
      });

      // 首先清空现有的电池图表实例数组
      this.batteryCharts.forEach(chart => chart && chart.dispose());
      this.batteryDisplays.forEach(display => display.remove());
      this.batteryCharts = [];
      this.batteryDisplays = [];
      // 获取放置图表的容器，这里假设您已经在模板中定义了一个容器
      const batteryChartsContainer = this.$refs.batteryChartsContainer;
      if (batteryChartsContainer) {
        batteryChartsContainer.innerHTML = ''; // 清空容器中的内容
        batteryChartsContainer.style.display = 'flex';
        batteryChartsContainer.style.flexWrap = 'wrap';
        batteryChartsContainer.style.justifyContent = 'flex-start';
        const optionTitlesAndUnits = {
          current: {title: this.$t('ProductDiagnosis.current')},
        };
        // 为每个选中的电池数据类型创建一个图表实例
        this.selectedBatteryOptions.forEach((selectedOption, index) => {
          // 检查选项类型，这里假设选项是一个简单的字符串，如 'current'
          if (selectedOption === 'current') {
            const chartContainer = document.createElement("div");
            chartContainer.style.height = "300px";
            chartContainer.id = `battery-chart-${index}`;
            batteryChartsContainer.appendChild(chartContainer);
            chartContainer.style.width = `${batteryChartsContainer.clientWidth / 2 - 10}px`;

            const chart = echarts.init(chartContainer);
            const optionDetail = optionTitlesAndUnits[selectedOption];
            const title = optionDetail ? optionDetail.title : this.$t('ProductDiagnosis.unknown'); // 如果找不到对应项，默认为'未知'
            const unit = this.getUnit(title); // 根据选项值获取单位

            const option = {
              title: {
                text: `${title} ${unit}`, // 使用选项的label作为图表标题
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow',
                },
              },
              grid: {
                left: '3%',
                right: '4%',
              },
              xAxis: {
                type: 'category',
                data: [] // 初始化为空，之后动态填充
              },
              yAxis: {
                type: 'value',
              },
              series: [{
                name: title, // 确保每个系列有一个name属性
                type: 'line',
                data: [], // 初始化为空，之后动态填充
                smooth: true,
                itemStyle: {
                  normal: {
                    color: 'rgb(0,154,68)',
                  },
                },
              }]
            };
            chart.setOption(option);
            this.batteryCharts.push(chart); // 将图表实例存储到数组中以便后续更新
          } else {

            // 确保selectedOption包含在下面这个对象的键中
            const descriptions = {
              "BatVoltage": this.$t('ProductDiagnosis.voltageValue'),
              "BatSoc": this.$t('ProductDiagnosis.socValue'),
              "BatRemCap": this.$t('ProductDiagnosis.remainingCapacityValue')
            };

            const titles = {
              "BatVoltage": this.$t('ProductDiagnosis.voltageUnit'),
              "BatSoc": this.$t('ProductDiagnosis.socPercent'),
              "BatRemCap": this.$t('ProductDiagnosis.remainingCapacityUnit')
            };

// 使用函数表达式来代替函数声明
            const getDescription = (option) => {
              return descriptions[option] || this.$t('ProductDiagnosis.unknownParameter'); // 如果option不在列表中，则返回"未知参数"
            };

            const getTitle = (option) => {
              return titles[option] || this.$t('ProductDiagnosis.unknownParameter'); // 如果option不在列表中，则返回"未知参数"
            };

            // 对于其他参数，创建参数显示
            const displayContainer = document.createElement("div");
            displayContainer.style.border = "1px solid #ddd";
            displayContainer.style.padding = "10px 20px"; // 可能需要调整以确保文本不贴近边框
            displayContainer.style.margin = "10px";
            displayContainer.style.textAlign = "center"; // 容器整体居中
            displayContainer.style.display = "inline-block";
            displayContainer.style.minWidth = "250px";
            displayContainer.style.height = "300px";
            displayContainer.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.1)";
            displayContainer.style.backgroundColor = "#fff";

// 创建内部flex容器仅用于垂直居中数值和描述
            const flexContainer = document.createElement("div");
            flexContainer.style.display = "flex";
            flexContainer.style.flexDirection = "column";
            flexContainer.style.justifyContent = "center";
            flexContainer.style.alignItems = "center";
            flexContainer.style.height = "100%"; // 使flexContainer占满displayContainer的高度

// 将需要居中的元素添加到flexContainer中
            flexContainer.innerHTML = `
<div class="battery-display-value" id="battery-value-${selectedOption}">0</div>
<span class="battery-display-description">${getDescription(selectedOption)}</span>`;

// 将顶部左侧的文字直接添加到displayContainer中，保持其位置
            displayContainer.innerHTML = `
<div class="battery-display-title">${getTitle(selectedOption)}</div>`;

// 将flexContainer添加到displayContainer中
            displayContainer.appendChild(flexContainer);
// 添加到容器
            batteryChartsContainer.appendChild(displayContainer);

            // 使用 nextTick 确保 DOM 更新后再设置样式
            this.$nextTick(() => {
              const valueElement = document.getElementById(`battery-value-${selectedOption}`);
              if (valueElement) {
                valueElement.style.fontSize = "40px";
                valueElement.style.color = "#000000D9";
                valueElement.style.fontFamily = "PingFang SC";
                valueElement.style.margin = "0px 0px 18px";
              }
              const titleElement = displayContainer.querySelector('.battery-display-title');
              if (titleElement) {
                titleElement.style.fontSize = "18px";
                titleElement.style.color = "#666";
                titleElement.style.textAlign = "left";
                titleElement.style.fontFamily = "PingFang SC";
                titleElement.style.margin = "PingFang SC";
              }
              const descriptionElement = flexContainer.querySelector('.battery-display-description');
              if (descriptionElement) {
                descriptionElement.style.fontSize = "18px";
                descriptionElement.style.color = "rgb(177, 177, 177)";
                descriptionElement.style.fontFamily = "PingFang SC";
                descriptionElement.style.margin = "0px 0px 18px";
              }
            });

            // 更新数值显示
            this.$watch(`batteryValues.${selectedOption}`, (newVal) => {
              const valueElement = document.getElementById(`battery-value-${selectedOption}`);
              if (valueElement) {
                // 确保 newVal 是一个数字，如果不是，则设置为 0 或其他默认值
                // 更新 valueElement 的文本内容
                valueElement.textContent = isNaN(newVal) ? 0 : newVal;
              }
            });
          }
        });
      } else {
        console.error("Battery charts container not found.");
      }
    },


    // 用于初始化并创建多个图表的方法
    initializeCharts() {
      this.motorCharts.forEach(chart => {
        chart.dispose(); // 销毁旧的图表实例
      });

      this.motorCharts = []; // 清空图表实例数组
      // 确保容器清空旧的图表
      const chartsContainer = document.querySelector('#motor-temp-chart');
      chartsContainer.innerHTML = ''; // 清空容器中的内容
      chartsContainer.style.display = 'flex'; // 使用 Flexbox 布局
      chartsContainer.style.flexWrap = 'wrap'; // 允许内容换行
      chartsContainer.style.justifyContent = 'flex-start'; // 在项目之间和周围提供一些空间

      this.selectedMotorOptions.forEach((selectedOption, index) => {
        const chartContainer = document.createElement("div");
        chartContainer.style.height = "300px"; // 设置高度

        chartContainer.id = `motor-chart-${index}`;
        chartsContainer.appendChild(chartContainer);

        chartContainer.style.width = `${chartsContainer.clientWidth / 2 - 10}px`; // 动态设置宽度，减去一些边距

        const chart = echarts.init(chartContainer);

        const fakeData = [];

        const unit = this.getUnit(selectedOption); // 根据选项值获取单位

        const option = {
          title: {
            text: `${selectedOption} ${unit}`,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          grid: {
            left: '3%',
            right: '4%',
          },
          xAxis: {
            type: 'category',
            data: fakeData.map(data => data.name),
          },
          yAxis: {
            type: 'value',
          },
          series: [{
            data: fakeData.map(data => data.value),
            type: 'line',
            itemStyle: {
              normal: {
                color: 'rgb(0,154,68)',
              },
            },
          }],
        };

        chart.setOption(option);
        this.motorCharts.push(chart); // 储存图表实例以便后续更新
      });
    },
    clearExistingCharts() {
      this.motorCharts.forEach(chart => chart.dispose());
      this.motorCharts = [];
      const chartsContainer = document.querySelector('#motor-temp-chart');
      if (chartsContainer) {
        chartsContainer.innerHTML = ''; // 清空容器中的内容以移除旧的图表
      }
    },

    simulateDataReceiving() {
      // 在设置新的定时器之前，先清除之前可能存在的定时器
      if (this.dataIntervalId) {
        clearInterval(this.dataIntervalId);
      }

      this.dataIntervalId = setInterval(() => {
        // 检查是否正在分析，并且至少选择了一个电机或电池的选项
        if (!this.isAnalyzing || (this.selectedMotorOptions.length === 0 && this.selectedBatteryOptions.length === 0)) {
          clearInterval(this.dataIntervalId);
          this.dataIntervalId = null; // 清除定时器ID
          return; // 直接返回，不执行后续的逻辑
        }
        // 检查是否选择了电机的选项，如果是，则请求实时电机信息
        if (this.selectedMotorOptions.length > 0) {
          this.sendCommand('getMotorInfo_RealTime');
        }

        // 检查是否选择了电池的选项，如果是，则请求实时电池信息
        if (this.selectedBatteryOptions.length > 0) {
          this.sendCommand('getBatteryInfo_RealTime');
        }
      }, 1000);
    },
    handleBatteryRealTimeWsMessage(message) {
      if (this.isAnalyzing && this.isRealTimeDataEnabled && message.cmd === "getBatteryInfo_RealTime") {
        if (!message.data || message.data === "") {
          console.error('Received empty battery data');
          return;
        }
        try {
          const batteryRealTimeData = JSON.parse(message.data);
          this.updateBatteryChartData(batteryRealTimeData);
        } catch (error) {
          console.error("Error handling battery WebSocket message:", error, "Received data:", message.data);
        }
      }
    },

    handleRealTimeWsMessage(message) {
      // 确保分析正在进行时才处理消息
      if ((!this.isAnalyzing && message.cmd !== "startMotorTest") || !this.isRealTimeDataEnabled) {
        return;
      }
      if (!message.data || message.data === "") {
        console.error('Received empty data');
        return;
      }
      let motorRealTimeData;
      switch (message.cmd) {
        case "getMotorInfo_RealTime":
          try {
            motorRealTimeData = JSON.parse(message.data);
            // 仅在必要时打印日志，或考虑移除频繁的日志输出
            if (this.isAnalyzing) { // 增加条件检查
              this.updateChartData(motorRealTimeData);
            }

          } catch (error) {
            console.error("Parsing error:", error);
            // 可以在这里处理错误，例如设置一个错误状态，或者忽略这次更新
          }
          break;
        case "startMotorTest":
          if (message.code !== 200) {
            console.error('startMotorTest command failed with code:', message.code);
            // 处理命令失败的情况
          }
          break;
        case 'stopMotorTest':
          break;
          // 处理其他消息类型...
      }
    },

    updateBatteryChartData(batteryData) {
      if (!this.isAnalyzing || this.batteryCharts.length === 0) {
        return; // 如果已经停止分析或图表实例数组为空，则不更新图表
      }


      // 假设我们已经为电池数据创建了图表实例并存储在 this.batteryCharts 中
      this.batteryCharts.forEach((chart, index) => {
        const selectedOption = this.selectedBatteryOptions[index];
        const batteryOutputKey = this.getBatteryOutputKey(selectedOption); // 假设电池选项直接使用value作为键

        if (chart && typeof chart.getOption === 'function') {
          const chartOption = chart.getOption();
          const timeCounterLabel = `${this.timeCounter}s`;

          // 更新X轴和系列数据
          const dataValue = parseFloat(batteryData[batteryOutputKey]) || 0;

          chartOption.xAxis[0].data.push(timeCounterLabel); // 添加时间标签
          chartOption.series[0].data.push(dataValue); // 添加实时数据值
          chart.setOption(chartOption);
        }
      });

      // 更新电池参数显示
      this.selectedBatteryOptions.forEach(option => {

        const valueElement = document.getElementById(`battery-value-${option}`);

        // 使用Object.prototype.hasOwnProperty.call来检查属性
        if (valueElement && this.batteryInfo && Object.prototype.hasOwnProperty.call(batteryData, option)) {
          // 对于特定的选项（如BatVoltage），进行转换
          let displayValue = batteryData[option];

          // 检查是否是数字，如果不是，则转换为0或适当的默认值
          if (isNaN(displayValue)) {
            displayValue = 0; // 或者其他合适的默认值
          }

          if (option === 'BatVoltage' || option === 'BatRemCap') {
            displayValue *= 1000; // 将值从伏特转换为毫伏
          }

          valueElement.textContent = displayValue;
        }
      });

      // 自增时间计数器
      this.timeCounter += 1;
    },

    updateChartData(message) {
      if (!this.isAnalyzing || this.motorCharts.length === 0) {
        return; // 如果已经停止分析或图表实例数组为空，则不更新图表
      }
      // 根据消息内容选择更新哪个图表
      this.motorCharts.forEach((chart, index) => {
        const selectedOption = this.selectedMotorOptions[index];
        const motorOutputKey = this.getMotorOutputKey(selectedOption);

        if (chart && typeof chart.getOption === 'function') {
          const chartOption = chart.getOption();
          const timeCounterLabel = `${this.timeCounter}s`;

          // 示例：更新X轴和系列数据
          chartOption.xAxis[0].data.push(timeCounterLabel); // 假设消息中包含时间戳
          const dataValue = message[motorOutputKey] ? parseFloat(message[motorOutputKey]) : 0;
          chartOption.series[0].data.push(dataValue); // 假设消息中包含实时值
          chart.setOption(chartOption);
        }
      });

      // 自增时间计数器
      this.timeCounter += 1;
    },

    getBatteryOutputKey(option) {
      // 根据选项值返回对应的消息中的键名
      switch (option) {
        case 'current':
          return 'BatRealTimeCur';
        default:
          return ''; // 如果没有匹配的选项，返回空字符串
      }
    },
    getMotorOutputKey(option) {
      // 根据选项值返回对应的消息中的键名
      switch (option) {
        case this.$t('ProductDiagnosis.power'):
          return 'motor_output_power';
        case this.$t('ProductDiagnosis.cadence'):
          return 'Crank_speed_in_RPM';
        case this.$t('ProductDiagnosis.speed'):
          return 'Bike_speed';
        case this.$t('ProductDiagnosis.torque'):
          return 'Torque_value';
        case this.$t('ProductDiagnosis.phaseCurrent'):
          return 'phase_current';
        default:
          return ''; // 如果没有匹配的选项，返回空字符串
      }
    },

    initializeWebSocket() {
      if (this.getWs) {
        this.getWs.onopen = () => {
          this.wsConnected = true; // 设置WebSocket为已连接
        };

        this.getWs.onclose = () => {
          this.wsConnected = false; // 设置WebSocket为已断开
        };

        this.getWs.onmessage = (event) => {
          const message = JSON.parse(event.data);
          this.handleRealTimeWsMessage(message);
        };
      } else {
        console.error("WebSocket is not initialized.");
      }
    },
  }
};
</script>
<style scoped>
.battery-display-title {
  font-size: 16px;
  color: #666;
  text-align: left;
}

.battery-display-value {
  font-size: 40px;
  font-weight: bold;
  color: #333;
}

.battery-display-description {
  font-size: 14px;
  color: rgb(177, 177, 177);
}

.parameter-display {
  border: 1px solid #ddd; /* 边框颜色和宽度 */
  padding: 10px; /* 内边距 */
  margin-bottom: 10px; /* 外边距，用于与下一个方框保持距离 */
  text-align: center; /* 文本居中 */
}

.parameter-title {
  font-size: 64px !important; /* 标题字体大小 */
  margin-bottom: 5px; /* 与值之间的间距 */
  color: #666; /* 标题字体颜色 */
}

.parameter-value {
  font-size: 24px; /* 值的字体大小 */
  font-weight: bold; /* 加粗 */
  color: #333; /* 值的字体颜色 */
}

.analysis-button {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.motor-font {
  font-size: 22px;
  /* 设置字体大小为图片的大小 */
  margin-left: 10px;
  /* 调整图片和字体之间的间距 */
}

.device-info {
  flex-wrap: wrap;
  justify-content: space-between;

}

.device-item {
  display: flex;
  flex: 0 0 calc(33.33% - 10px);
  /* 三列布局，可根据需求调整间距和宽度 */
  line-height: 1.5;
  height: 80px;
  align-items: center;
}

.device-item p {
  /* 调整左右间距，上下间距 */
}

.field {
  /* 添加样式来控制字段和值之间的间距和布局 */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 将字段和值在水平方向上居中对齐 */
  margin-top: 10px;
  /* 调整字段和值之间的垂直间距 */
  margin-right: 60px;
  margin-left: 60px;
  font-size: 14px;
}

.field-label {
  margin-bottom: 5px;
  /* 调整字段和值之间的垂直间距 */
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.custom-button {
  background-color: rgb(245, 245, 245); /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217); /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184); /* 设置边框颜色为红色，如果需要 */
}

.custom-button:hover {
  background-color: rgb(245, 245, 245); /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217); /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184); /* 鼠标悬停时保持相同的文字颜色 */
}

/* 当按钮可点击时应用的样式 */
.button-active {
  background-color: rgb(0, 154, 68); /* 设置背景为白色 */
  color: white; /* 可以调整文字颜色以保持可读性 */
  border-color: #dcdfe6; /* 可选：设置边框颜色 */
}

/* 悬停在 .button-active 上时的样式 */
.button-active:hover {
  color: white; /* 悬停时文字颜色为绿色 */
  border-color: rgb(0, 154, 68); /* 悬停时边框颜色为绿色 */
  background-color: rgb(0, 154, 68); /* 悬停时背景色为白色 */
}

</style>
